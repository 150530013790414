<template>
  <div class="wrapper">
    <div class="container">
      <div v-for="(article, index) in articleList" v-bind:key="index+`article`">
        <router-link :to="article.r">
      <figure>
        
        <img :src="article.ci" :alt="article.h" />
        <figcaption><strong>{{ article.h }}</strong></figcaption>
      </figure>
      </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters} from 'vuex'
  import Worker from "worker-loader!../worker";
  //import imageModule from '@/store/image'

  export default {
    name: "NewsWall",
    components: {
      
    },
    props: {
      parentView: {
        type: String
      },


    },
    data () {
      return {

        articleList: [],
        
      }
    },
    computed: {
      ...mapGetters('category', [
        'currentCategory',
        'currentParentCategory'
      ]),
      
      
      
    },
    watch: {
      
      
    },
    methods: {
      //...mapActions('image', [
      //  'getHeroImage'
      //]),
      async fetchTheData () {
        return new Promise((resolve) => {
          //first try to fetch from cache

          //use a web worker
          const workerA = new Worker()
          workerA.postMessage({cmd: "getArticles"})
          workerA.onmessage = e => {
            var articleList = JSON.parse(e.data.payload)
            for (var key  in articleList) {
              this.articleList.push(articleList[key])
              //this.routineSearchItemStatusArray.push(false)
            } 
          }


          resolve()
        })
      },

  
    },
    beforeCreate () {
      
      //if(!this.$store.state.image) this.$store.registerModule('image', imageModule)

      
    },
    async created () {
      await this.fetchTheData();
      //this.searchLabelComputer();
      
    
    },
    async mounted () {
      //this.$refs.searchBox.focus()
        
          
          

      
    },
    updated () {
      
    }
  }
</script>

<style scoped>
.wrapper {
  width: 100%;
  overflow: hidden;
}
a {
  color: inherit;
  text-decoration: none;
}

/* NewsWall */
img {
  max-width: 100%;
  display: block;
}

figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 5px;
  break-inside: avoid;
}

figure > img {
  grid-row: 1 / -1;
  grid-column: 1;
}

figure a {
  color: black;
  text-decoration: none;
}

figcaption {
  grid-row: 2;
  grid-column: 1;
  background-color: rgba(255,255,255,.5);
  padding: .2em .5em;
  justify-self: start;
  font-size: .9em;
}

.container {
  column-count: 2;
  column-gap: 5px;
  background-color: black;
}


@media screen and (min-width: 700px) {
  figcaption {
    font-size: 1.1em;
  }
  .container {
    column-gap: 10px;
    column-count: 3;
  }

}

</style>