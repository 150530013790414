<template>
  <div class="wrapper7">
    <div class="routineTags2">
      <div class="tagChip2" v-for="(cat, index) in catCloudArray"  v-bind:key="`category`+index">
        <router-link :to="`/routine/search/for/`+cat">{{ cat }}</router-link>
      </div>
    </div>
    




  </div>
</template>

<script>
  //Some example imports you could have:
    //import Worker from "worker-loader!@/worker";
    //import feelingModule from '@/store/feeling'
    //import { mapGetters, mapActions } from 'vuex'
    //import '@/plugins/vuetify'
    //import MevChains from '@/components/MevChains'
    import Worker from "worker-loader!../worker";
    import { mapGetters, mapActions } from 'vuex'
    //import { VSelect } from 'vuetify/lib'

  export default {
    name: "RoutineSearch",
    components: {
      
    },
    props: {
      parentView: {
        type: String
      },
    },
    data () {
      return {

        routineList: [],



        
      }
    },
    computed: {
      ...mapGetters('auth', {
        profile: 'profile',
        loggedIn: 'loggedIn',
        role: 'role',
      }),

      /*
      resultList () {
        if((this.routineList.length > 0) && this.filter) {
          
          var newFilter = this.filter
          
          var resultArray = []
          var routineListArray = []
          var scoreArray = []

          //determine a score for each routine
          this.routineList.forEach((routine) => {
            var searchStringArray, filterStringArray
            var searchString = ""
            var title = (routine.rt) ? routine.rt.toUpperCase().trim() : ""
            var tags = (routine.t) ? routine.t.toUpperCase().trim() : ""
            var summary = (routine.rs) ? routine.rs.toUpperCase().trim() : ""
            var type = (routine.rty) ? routine.rty.toUpperCase().trim() : ""
            var code = (routine.rc) ? routine.rc.toUpperCase().trim() : ""
            var intentMean = (routine.im) ? routine.im.toUpperCase().trim() : ""

            searchString += title + " "
            searchString += tags + " "
            searchString += summary + " "
            searchString += type + " "
            searchString += code + " "
            searchString += intentMean + " "
            searchString = searchString.trim()
            var score = 0
            //split the searchString, put into word array, searchStringArray
            searchStringArray = searchString.split(" ")
            //split this.filter up into a word array, filterStringArray
            filterStringArray = newFilter.toUpperCase().split(" ")
            //forEach word in filterStringArray, check if the searchStringArray contains that word, if it does, increment score by 1
            filterStringArray.forEach(word => {
              if(searchStringArray.includes(word)) {
                ++score
              }
            })
            //check if filter string has a direct match in the searchString
            if(searchString.indexOf(newFilter.toUpperCase()) > -1) {
              ++score
            }
            //create a score array
            scoreArray.push(score)
            //clone this.routineList into another array
            routineListArray.push(routine)
          })
          
          //combine the routineList with the score array
          var combinedRoutineScoreArray = []
          routineListArray.forEach((routine,idx) => {
            var obj = {
              ...routine,
              'score': scoreArray[idx]
            }
            combinedRoutineScoreArray.push(obj)
          })

          //sort by score, desc
          combinedRoutineScoreArray.sort((a,b) => {
            return (b.score - a.score)
          })

          resultArray = combinedRoutineScoreArray.filter(routine => {
            return routine.score > 0
          })

          
          var resultArray2 = null
          if(this.daysOfWeekFilter && ((this.daysOfWeekFilter != `-- Filter Off --`)&&(this.daysOfWeekFilter != 'Filter by days per week'))) {
            resultArray2 = resultArray.filter(routine => {
              return routine.t.includes(this.daysOfWeekFilter)
            })
          }else{
            resultArray2 = resultArray
          }
          

          return resultArray.slice(0,40)     //puts a limit of forty on the results

        }else{
          return []
        }
      },

      */

      catCloudArray () {
        if(this.routineList.length > 0) {
          var catCloudArray = []
            this.routineList.forEach(async (routine) => {
              if(!catCloudArray.includes(routine.im))
              catCloudArray.push(routine.im)
            })
            return catCloudArray
        } else {
          return []
        }
      },




    },
    watch: {

      /*
      resultList: {
        deep: true,
        handler: async function (newVal) {
          if(newVal) {         //This getTheData pattern skips the first iteration and process the rest
            if((newVal.length == 0) && (this.showSearchPanel)) {
              this.showRequestRoutineForm = true
            }else{
              this.showRequestRoutineForm = false
            }
          }
        }
      },
      */

    },
    methods: {
      
      ...mapActions('auth', [
        'setEditMode'
      ]),


      async fetchTheData () {
        return new Promise((resolve) => {
          //first try to fetch from cache

          //use a web worker
          const workerA = new Worker()
          workerA.postMessage({cmd: "getActiveRoutines"})
          workerA.onmessage = e => {
            var routineList = JSON.parse(e.data.payload)
            for (var key  in routineList) {
              this.routineList.push(routineList[key])
              //this.routineSearchItemStatusArray.push(false)
            } 
          }


          resolve()
        })
      },



      

    },
    beforeCreate () {
      
      
    },
    async created () {
      await this.fetchTheData()
      



    
    },
    mounted () {


    },
    updated () {
      
    }
  }
</script>

<style scoped>
.wrapper7 {
  width: 100%;
  text-align: center;
}
a {
  color: inherit;
  text-decoration: none;
}
.card {
  padding: 20px;
  min-height: 500px;
  color: black;
}

.tagChip2 {
  display: inline-block;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: rgb(184, 184, 184);
  color: black;
  border-radius: 4px;
  margin: 5px;
  font-weight: 700;
}
.routineTags2 {
  background-color: transparent;
  padding: 5px;
  margin-bottom: 20px;
  font-family: 'Quicksand', sans-serif;
    font-size: .85em;
    font-weight: 400;

}



</style>