<template>
  <div class="search-wrapper">
    
    
    <div for="mySearch" id="searchLabel">
      <div v-if="parentView === 'Routine'">
        <span style="font-weight: 600;">Search and Add Routines</span><br/><span style="font-size: .85em;">What behavior, or action, do you want to start doing?</span><br />
      </div>
      <div v-else>
        What behavior, or action, do you want to start doing?<br />
      </div>
    </div>
    <input ref="searchBox" 
          v-on:keyup="myFunction2"
          type="text" 
          id="mySearch">

    

    <div v-if="showSearchPanel">

      <!--
      <div class="filterContainer">
        <div class="filterHolder">
          
          
          
            <v-select
              clearable
              id="daysOfWeekFilter"
              :items="daysOfWeekFilterItems"
              label="Filter routines by days per week"
              v-model="daysOfWeekFilter">
            </v-select>
          
           
          
          
          <div class="customSelect">
            <select v-model="daysOfWeekFilter" id="daysOfWeekFilter7" @input="filterInput" >
              <option value="Filter by days per week">Filter by days per week</option>
              <option v-for="item in daysOfWeekFilterItems" :value="item" v-bind:key="item">{{ item }}</option>
              <option value="-- Filter Off --">-- Filter Off --</option>
            </select>
          </div>
          

        </div>
      </div>
      -->
      

      <ul id="search_routineList">
        <li v-for="(routine, index) in filteredResultList" v-bind:key="'searchResult'+index" :id="'searchResult'+index" class="listItem">
          <div class="itemWrapper">
            <div class="routineSearchItem" v-on:click="expandDetail({routine:routine, index:index})">

                <div class="iconHolder" v-if="routineSearchItemStatusArray[index]">
                  <img :src="routine.ig" class="icon">
                </div>
                <div class="iconHolder" v-else>
                  <img :src="routine.ib" class="icon">
                </div> 
                <div class="searchTitle" :id="`searchTitle`+index">
                  <span>{{ (routine.rt) ? routine.rt : "null"}} </span>
                  <span class="noSee">{{ (routine.t) ? routine.t : ""}}</span>
                  <span class="noSee">{{ (routine.l) ? routine.l : ""}}</span>
                </div>

                <!--
                <div class="daysOfWeek" v-if="(routine.rty == `exercise`)">
                  <div v-for="(day, indx) in routine.s" v-bind:key="indx"  class="daysOfWeekBar">
                    <span class="dayBubbleUnset" v-if="day == ''">
                      {{ daysOfWeekArray[indx] }}
                    </span>
                    <span class="dayBubbleSet" v-else>
                      {{ daysOfWeekArray[indx] }}
                    </span> 
                  </div>
                </div> 

                <div class="daysOfWeek" v-if="(routine.rty == `exercise2`)">
                  <div v-for="(day, indx) in routine.s" v-bind:key="indx"  class="daysOfWeekBar">
                    <span class="dayBubbleUnset" v-if="day == ''">
                      {{ daysOfWeekArray[indx] }}
                    </span>
                    <span class="dayBubbleSet" v-else>
                      {{ daysOfWeekArray[indx] }}
                    </span> 
                  </div>
                </div> 
                -->
                
                
                <div class="expandChevron" :id="`expandChevron`+index">
                  <i v-if="!routineSearchItemStatusArray[index]" class="fas fa-chevron-down"></i>
                  <i v-else class="fas fa-chevron-up"></i>
                </div>
            </div>
            

            <transition name="fade">
              <div class="routineSearchItemDetail" :id="'routineSearchItemDetail'+index" v-if="routineSearchItemStatusArray[index]">
                <div class="routineDetailWrapper">
                  
                  <div class="routineDesc">
                    {{ routine.rs }}

                  </div>
                  <div class="starBackground" ref="star">
                    <img :src="require('@/assets/smallStar_grey_filled.png')" alt="heedi" class="starImg">
                  </div>
                  <div class="routineTags">
                    <div class="tagChip" v-for="(tag, idx) in tagCloudsArray[index]"  v-bind:key="`tag2`+tag+idx" @click="handleTagClick(tag)" >
                      
                      {{tag}}
                      
                    </div>
                  </div>
                  
                  
                  
                  
                  <div class="detailsHolder">
                    <div class="leftSide">
                      <br>
                      <div class="routineLevel" v-if="routine.rty == `exercise`">
                        {{ routine.l }}
                      </div>
                      <br>
                      

                      <!-- 
                      <div v-if="routine.rty == `exercise`">
                        <div class="daySchedule" v-for="(schedule, key) in routineSchedules[index]" v-bind:key="key">
                          <div class="dayLine">
                            {{ schedule.day }}
                          </div>
                          <div class="scheduleLine" v-for="(session, idx) in schedule.sessions" v-bind:key="idx">
                            {{ ++idx +": "+session }}
                          </div>
                        </div>
                      </div>
                      -->

                      <!-- WORK SESSIONS -->
                      <div v-if="routine.rty === 'work sessions'">
                        <div class="daySchedule" v-for="(schedule, key) in routineSchedules[index]" v-bind:key="key">
                          <div class="dayLine">
                            {{ schedule.day }}
                          </div>
                          <div class="scheduleLine" v-for="(session, idx) in schedule.sessions" v-bind:key="idx">
                            {{ session+" "+ ++idx }}
                          </div>
                        </div>
                      </div>





                      <!-- 
                      <div v-else-if="routine.rty == `exercise2`">
                        <div class="daySchedule daySchedule_exercise2" v-for="(schedule2, key) in routinePeriodSchedules[index]" v-bind:key="`period`+key">
                          <div class="dayLine">
                            {{ schedule2.period }} <span style="font-size:.8em;">- {{ schedule2.weeks }} {{ (schedule2.weeks > 1)? 'weeks':'week' }}</span>
                          </div>
                          <div class="scheduleLine scheduleLine_exercise2">
                            <div v-for="(action, idex) in routinePeriodActions[schedule2.period]" v-bind:key="`periodAction`+index+idex">
                              {{ action }}&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        </div>
                      </div>

                      EXERCISE2 -->

                      <!-- CESSATION -->
                      <div v-else-if="routine.rty == `cessation`">
                        <div class="summaryText">
                          {{ routine.cs }}
                          
                        </div>
                      </div>

                      <!-- EVERYDAY HABIT -->
                      <div v-else-if="routine.rty == `everyday habit`">
                        <div class="summaryText">
                          {{ routine.tpd }} times per day
                          
                        </div>
                      </div>


                      

                      <br /><br />



                    </div>
                    <div class="rightSide">
                      <!--
                      <div 
                        @click="modifyRoutine(routine)" class="addToRoutineButton button2"
                        v-if="role === 'superDuperAdmin'"
                        >
                        Modify this Routine
                      </div>
                      -->
                      <div @click="addRoutine(routine)" class="addToRoutineButton button1">
                        Add to Routine
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <!-- <router-link :to="'/category/' + item.mc">   uncomment to enable production version
          <router-link :to="'/evolve/'+item.mc">
            <b>{{ item.mc }}</b>&nbsp;&nbsp;<i class="fas fa-arrow-right fa-xs"></i>&nbsp;&nbsp;&nbsp;<span class="ends" v-for="(i, k) in item.re" v-bind:key="k"> {{ i }}&nbsp;&nbsp;&nbsp;</span>
          </router-link>
          -->
        </li>
      </ul>
    </div>

    
    



    <div>
      <div v-if="showRequestRoutineForm" class="requestFormHolder">
        <div class="filterText">
          <span style="font-weight: 700;font-size: 1.3em">"{{ filter }}"</span>
        </div>
        <br />
        <div class="routineRequestText">
          No routines are matching your search; let's change that.
        </div>
        <!--
        <br />
        <div>
          <textarea 
            v-model="requestText" 
            placeholder="describe routine"
            cols="40"
            rows="4"
            ></textarea>
        </div>
        <br />
        -->
        <br>
        <div 
          class="addToRoutineButton button1"
          @click="requestRoutine"
        >
        Request This Routine</div>

        <br />
        <br />
        <br />
      </div>
    </div>

  </div>
</template>

<script>
  //Some example imports you could have:
    //import Worker from "worker-loader!@/worker";
    import routineModule from '@/store/routine'
    //import { mapGetters, mapActions } from 'vuex'
    //import '@/plugins/vuetify'
    //import MevChains from '@/components/MevChains'
    
    
    import Worker from "worker-loader!../worker";
    import { mapGetters, mapActions } from 'vuex'
    //import { VSelect } from 'vuetify/lib'

  export default {
    name: "RoutineSearch",
    components: {
      
    },
    props: {
      parentView: {
        type: String
      },
      searchString: {
        type: String
      }
    },
    data () {
      return {
        showSearchPanel: false,
        routineList: [],
        routineSearchItemStatusArray: [],
        showRequestRoutineForm: false,

        routineSchedules: {},
        routinePeriodSchedules: {},
        routinePeriodActions: {},
        daysOfWeekArray: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        searchScoreArray: [],


        filter: null,

        requestText: null,

        daysOfWeekFilter: "Filter by days per week",
        daysOfWeekFilterItems: [ 
          '2 days: MON, THU',
          '2 days: TUE, FRI',
          //'2 days: WED, SAT',             //Will implement these later
          //'2 days: THU, SUN',             //Will implement these later
          '3 days: MON, WED, FRI',
          '3 days: TUE, THU, SAT',
          //'3 days: WED, FRI, SUN',
          //'4 days: MON, TUE, THU, FRI',
          '4 days: TUE, WED, FRI, SAT',
          '4 days: WED, THU, SAT, SUN',
        ]
        
      }
    },
    computed: {
      ...mapGetters('auth', {
        profile: 'profile',
        loggedIn: 'loggedIn',
        role: 'role',
      }),
      

      resultList () {
        if((this.routineList.length > 0) && this.filter) {
          
          var newFilter = this.filter
          
          var resultArray = []
          var routineListArray = []
          var scoreArray = []

          //determine a score for each routine
          this.routineList.forEach((routine) => {
            var searchStringArray, filterStringArray
            var searchString = ""
            var title = (routine.rt) ? routine.rt.toUpperCase().trim() : ""
            var tags = (routine.t) ? routine.t.toUpperCase().trim() : ""
            var summary = (routine.rs) ? routine.rs.toUpperCase().trim() : ""
            var type = (routine.rty) ? routine.rty.toUpperCase().trim() : ""
            var code = (routine.rc) ? routine.rc.toUpperCase().trim() : ""
            var intentMean = (routine.im) ? routine.im.toUpperCase().trim() : ""
            var level = (routine.l) ? routine.l.toUpperCase().trim() : ""

            searchString += title + " "
            searchString += tags + " "
            searchString += summary + " "
            searchString += type + " "
            searchString += code + " "
            searchString += intentMean + " "
            searchString += level + " "
            searchString = searchString.trim()
            var score = 0
            //split the searchString, put into word array, searchStringArray
            searchStringArray = searchString.split(" ")
            //split this.filter up into a word array, filterStringArray
            filterStringArray = newFilter.toUpperCase().split(" ")
            //forEach word in filterStringArray, check if the searchStringArray contains that word, if it does, increment score by 1
            filterStringArray.forEach(word => {
              if(searchStringArray.includes(word)) {
                ++score
              }
            })
            //check if filter string has a direct match in the searchString
            if(searchString.indexOf(newFilter.toUpperCase()) > -1) {
              ++score
            }
            //create a score array
            scoreArray.push(score)
            //clone this.routineList into another array
            routineListArray.push(routine)
          })
          
          //combine the routineList with the score array
          var combinedRoutineScoreArray = []
          routineListArray.forEach((routine,idx) => {
            var obj = {
              ...routine,
              'score': scoreArray[idx]
            }
            combinedRoutineScoreArray.push(obj)
          })

          //sort by score, desc
          combinedRoutineScoreArray.sort((a,b) => {
            return (b.score - a.score)
          })

          resultArray = combinedRoutineScoreArray.filter(routine => {
            return routine.score > 0
          })

          /*
          var resultArray2 = null
          if(this.daysOfWeekFilter && ((this.daysOfWeekFilter != `-- Filter Off --`)&&(this.daysOfWeekFilter != 'Filter by days per week'))) {
            resultArray2 = resultArray.filter(routine => {
              return routine.t.includes(this.daysOfWeekFilter)
            })
          }else{
            resultArray2 = resultArray
          }
          */

          return resultArray.slice(0,40)     //puts a limit of forty on the results

        }else{
          return []
        }
      },

      tagCloudsArray () {
        if(this.filteredResultList.length > 0) {
          var tagCloudsArray = []
            this.filteredResultList.forEach(async (routine) => {
              //split tags by comma
              var splitTagArray = routine.t.split(",")
              var cleanArray = splitTagArray.map((tag) => {
                var str = tag.trim()
                
                  return str
                
              })
              cleanArray.pop()
              tagCloudsArray.push(cleanArray)
            })
            return tagCloudsArray
        } else {
          return []
        }
      },




      filteredResultList () {
        var clone = this.resultList
        if((this.resultList.length > 0) && (this.daysOfWeekFilter) && (this.daysOfWeekFilter != "Filter by days per week") && (this.daysOfWeekFilter != `-- Filter Off --`)) {
          

          var filteredResults = clone.filter(routine => {
            if(routine.t) {
              var upper = routine.t.toUpperCase()
              return upper.indexOf(this.daysOfWeekFilter.toUpperCase()) > -1
            }else{
              return clone.slice(0,14)
            }
            
          })

          return filteredResults.slice(0,14)     //puts a limit of fourteen on the results
          
        }else{
          return clone.slice(0,14)
        }
      },

    },
    watch: {

      resultList: {
        deep: true,
        handler: async function (newVal) {
          if(newVal) {         //This getTheData pattern skips the first iteration and process the rest
            if((newVal.length == 0) && (this.showSearchPanel)) {
              this.showRequestRoutineForm = true
            }else{
              this.showRequestRoutineForm = false
            }
          }
        }
      },

      searchString: {
        deep: true,
        handler: async function (newVal) {
          if(newVal) {         //This getTheData pattern skips the first iteration and process the rest
            document.getElementById("mySearch").value = this.searchString
            document.getElementById("mySearch").blur()
            this.myFunction2()
          }
        }
      }, 

      parentView: {
        deep: true,
        handler: async function (newVal) {
          if(newVal == 'Routine') {         //This getTheData pattern skips the first iteration and process the rest
            document.getElementById("mySearch").focus()
            
          }
        }
      }

    },
    methods: {
      
      ...mapActions('auth', [
        'setEditMode'
      ]),
      ...mapActions('routine', [
        'setIconLibrary'
      ]),
      
      initSearchPanel () {
        //this.showSearchPanel=true;
        return;
      },

      filterInput () {
        if(this.daysOfWeekFilter === '-- Filter Off --') {
          this.daysOfWeekFilter = 'Filter by days per week'
        }
      },

     



      requestRoutine () {
        this.$router.push(`/routine/request/`+this.filter)
      },

      async initializeStatuses () {
        return new Promise((resolve) => {
          if(this.routineList.length > 0) {
            //console.log("you got mail")
            var routineSearchItemStatusArray = []
            this.routineList.forEach(async () => {   
              routineSearchItemStatusArray.push('false')
            })
            this.routineSearchItemStatusArray = routineSearchItemStatusArray
            resolve()
          }
        })
      },
      async initializeTagClouds () {
        return new Promise((resolve) => {
          if(this.routineList.length > 0) {
            var tagCloudsArray = []
            this.routineList.forEach(async (routine) => {
              //split tags by comma
              var splitTagArray = routine.t.split(",")
              tagCloudsArray.push(splitTagArray)
            })
            this.tagCloudsArray = tagCloudsArray
            resolve()
          }
        })
      },
      myFunction2 () {
        var input = document.getElementById("mySearch");
        if(input.value.length < 1) {
          this.showSearchPanel = false
          this.filter = null 
          this.routineSearchItemStatusArray = []
          return
        }
        this.filter = input.value
        //this.$nextTick(() => {
          this.showSearchPanel = true
        //})

      },
      myFunction () {
        var input, filter, ul, li, i
        input = document.getElementById("mySearch");
        if(input.value.length < 1) { 
          this.showSearchPanel=false





          return
        }
          
        this.showSearchPanel=true;
        filter = input.value.toUpperCase();
        ul = document.getElementById("search_routineList");
        li = ul.getElementsByTagName("li");

        for (i = 0; i < li.length; i++) {
          //method 2
          var spans = li[i].getElementsByTagName("span")
          if ((spans[0].innerHTML.toUpperCase().indexOf(filter) > -1) || (spans[1].innerHTML.toUpperCase().indexOf(filter) > -1)) {
            li[i].style.display = "";

            
          } else {
            li[i].style.display = "none";
          }
          
        }
        
          
      },
      expandDetail (payload) {
        if(!this.routineSearchItemStatusArray[payload.index]) {
          this.$set(this.routineSearchItemStatusArray, payload.index, true)
          this.routineSearchItemStatusArray.splice(payload.index, 1, true)
          document.getElementById(`searchResult`+payload.index).style.backgroundColor = "rgb(67,67,66)"
          document.getElementById(`searchTitle`+payload.index).style.color = "white"
          document.getElementById(`expandChevron`+payload.index).style.color = "white"

          if(payload.routine.s) {
            var schedule = []
            payload.routine.s.forEach((str,index) => {
              if(str != "") {
                var sessions = str.split("-")
                var day
                if(index == 0) day = "Monday"
                if(index == 1) day = "Tuesday"
                if(index == 2) day = "Wednesday"
                if(index == 3) day = "Thursday"
                if(index == 4) day = "Friday"
                if(index == 5) day = "Saturday"
                if(index == 6) day = "Sunday"
                var sessionStrings = {}
                for(var i = 0; i < sessions.length; i++) {
                  var str2 = sessions[i]
                  var tod = str2.charAt(0)
                  var setNum = str2.charAt(1)
                  var timeOfDay
                  //console.log("tod", tod)
                  if(tod == "m") timeOfDay = "morning"
                  if(tod == "a") timeOfDay = "afternoon"
                  if(tod == "e") timeOfDay = "evening"
                  if(tod == "l") timeOfDay = "late night"

                  if(payload.routine.rty === 'work sessions') {
                    sessionStrings[i] = timeOfDay+' session'
                  }else {
                    sessionStrings[i] = timeOfDay+' - '+setNum+' sets'
                  }
                }
                var obj = {'day': day,'sessions': sessionStrings,}
                schedule.push(obj)
              }
            })
            this.routineSchedules[payload.index] = schedule
          }

          if(payload.routine.p) {
            var schedule2 = []
            payload.routine.p.forEach((string) => {
              if(string != "") {
                var parts = string.split("#")
                var period = parts[0]
                var weeks = parts[1]
                var obj = {'period': period, 'weeks': weeks}
                schedule2.push(obj)
              }
            })

            //schedule2 needs to be arranged so that Pre-Training is in position 0, Endurance in 1, Hypertrophy in 2, Strength in 3
            var newScheduleArray = []
            var preTrainIndex = schedule2.findIndex((s) => {
              return s.period == "Pre-Training"
            })
            if(preTrainIndex >= 0) {newScheduleArray.push(schedule2[preTrainIndex])}
            var enduranceIndex = schedule2.findIndex((s) => {
              return s.period == "Endurance"
            })
            if(enduranceIndex >= 0) {newScheduleArray.push(schedule2[enduranceIndex])}
            var hypertrophyIndex = schedule2.findIndex((s) => {
              return s.period == "Hypertrophy"
            })
            if(hypertrophyIndex >= 0) {newScheduleArray.push(schedule2[hypertrophyIndex])}
            var strengthIndex = schedule2.findIndex((s) => {
              return s.period == "Strength"
            })
            if(strengthIndex >= 0) {newScheduleArray.push(schedule2[strengthIndex])}


            this.routinePeriodSchedules[payload.index] = newScheduleArray
          }


          if(payload.routine.pha) {
 
            Object.keys(payload.routine.pha).forEach((period) => {
              this.routinePeriodActions[period] = []
              payload.routine.pha[period].forEach((action) => {
                
                this.routinePeriodActions[period].push(action)
                
              })
              
            })

            
          }


        }else {
          this.$set(this.routineSearchItemStatusArray, payload.index, false)
          this.routineSearchItemStatusArray.splice(payload.index, 1, false)
          document.getElementById(`searchResult`+payload.index).style.backgroundColor="#ececec"
          document.getElementById(`searchTitle`+payload.index).style.color = "black"
          document.getElementById(`expandChevron`+payload.index).style.color = "black"
        }
        
      },
      async fetchTheData () {
        return new Promise((resolve) => {
          
          

          //use a web worker
          const workerA = new Worker()
          workerA.postMessage({cmd: "getActiveRoutines"})
          workerA.onmessage = e => {
            var routineList = JSON.parse(e.data.payload)
            for (var key  in routineList) {
              this.routineList.push(routineList[key])
              this.routineSearchItemStatusArray.push(false)

              /*
              //build routine icon library
              var title = routineList[key].rt   //use the title as the main library key
              
              if(!iconLibrary[title]) {
                iconLibrary[title] = {}
              }
              iconLibrary[title].iconBlack = (routineList[key].ib)
              iconLibrary[title].iconGrey = (routineList[key].ig)
              this.setIconLibrary(iconLibrary)
              */


            } 
          }


          resolve()
        })
      },

      handleTagClick (tag) {

        this.$router.push('/routine/search/for/'+tag)
      },



      async addRoutine (routine) {
        this.setEditMode(true)
        //await this.addRoutineToUser(routine)
        //this.$router.push('/console/add/'+routine.rty+'/'+routine.id)
        this.$router.push('/console/initialize/'+routine.rty+'/'+routine.id+'/'+encodeURIComponent(routine.rt))
      },



      async modifyRoutine (routine) {
        //await this.addRoutineToUser(routine)
        this.$router.push("/admin/modify/"+routine.rty+"/"+routine.id)
      }
      

    },
    beforeCreate () {
      if(!this.$store.state.routine) this.$store.registerModule('routine', routineModule)
      
    },
    async created () {
      await this.fetchTheData()
      this.initializeStatuses()
      

      this.$emit('routine-data', this.routineList)


    
    },
    mounted () {

      if(this.parentView == "Routine") {
        document.getElementById("mySearch").focus();
      }

      

      
      
      
    },
    updated () {
      
    }
  }
</script>

<style scoped>
.wrapper {
  width: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}
.card {
  padding: 20px;
  min-height: 500px;
  color: black;
}
.noSee {
  display: none;
}
.fade-enter-active, .fade-leave-active {
  transition: all .1s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-10%);
}

.search_wrapper {
  width: 100%;
  background-color: white;
  

}
/* Style the search box */
#mySearch {
  /* display: block; */
  right: 2%;
  width: 90%;
  max-width: 800px;
  font-size: 18px;
  padding: 11px;
  border: 1px solid rgb(197, 197, 197);
  background-color: rgb(243, 241, 213);
  margin-bottom: 15px;
}

#searchLabel {
  /* margin-left: 7px; */
  font-family: 'Kreon', serif;
  font-weight: 700;
  font-size: 1.5em;
  padding-top: 35px;
  padding-bottom: 9px;
  padding-right: 30px;
  padding-left: 30px;
}

.searchFieldWrapper {
  margin: 0 auto;
  padding: 20px;
  
}
#search_routineList  {
  list-style: none;
  padding-left: 0;
  max-width: 600px;
  margin: 0 auto;
}
#search_routineList > li {
  width: 100%;
  border-top: 1px solid rgb(195, 195, 195);
  background-color: #f7f7f7;
}
#search_routineList > li:last-child {

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}


.routineSearchItem {
  font-family: 'Quicksand', sans-serif;
  font-size: 1em;
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  
}
.iconHolder {
  display: inline-block;
  left: 0;
  top: 0;
  width: 50px;
  vertical-align: top;
  margin-right: 20px;
  margin-left: 0px;
}
.icon {
  width: 50px;
}
.expandChevron {
  position: absolute;
  top: 10px;
  right: 0;
  cursor: pointer;
}
.itemWrapper {
  padding: 15px;
}

.routineDetailWrapper {
  background-color: rgb(22, 22, 22);
  padding: 15px;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 50px;
  border-radius: 7px;
  position: relative;
  overflow: hidden;
}
.starBackground {
  opacity: 0.07;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 50;
  pointer-events: none;
  
}
.starImg {
  width: 300px;
  height: 300px;
  z-index: 50;
}
.routineDesc {
  font-family: 'Quicksand', sans-serif;
  background-color: transparent;
  position: relative;
  display: inline-block;
  width: 90%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgb(207, 207, 207);
  font-size: 1.35em;
}
.detailsHolder {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  max-width: 1100px;
}
.detailsHolder > .rightSide {
  grid-column-start: 1;
  grid-row-start: 1;
}
.leftSide {
  background-color: transparent;
  position: relative;
  display: inline-block;
  padding: 10px;
  width: 100%;
}
.daySchedule {
  font-size: 1em;
  padding: 2px;
  text-align: left;
  background-color: transparent;
  margin: 0 auto;
  width: 180px;
}
.daySchedule_exercise2 {
  width: 240px;
}
.scheduleLine_exercise2 {
  font-size: .9em;
}

.dayLine {
  border-bottom: 2px solid #4d4d4d;
  font-weight: 600;
  color:rgb(155, 155, 155);
  width: 100%;
}
.scheduleLine {
  padding: 3px;
  padding-left: 15px;
  color:rgb(228, 228, 228);
}
.scheduleLine:last-child {
  margin-bottom: 15px;
}
.rightSide {
  background-color: transparent;
  position: relative;
  display: inline-block;
  padding: 10px;
  text-align: center;
  width: 100%;
}
.summaryText {
  color: rgb(155,155,155);
  font-weight: 600;
  padding: 10px;
  width: 200px;
  margin: 0 auto;
}



/* Search Listings */
.searchTitle {
  display: inline-block;
  font-size: 1.3em;
  width: 160px;
  cursor: pointer;
  padding-left: 4px;
  font-weight: 600;
}
.dayBubbleSet {
  display: inline-block;
  background-color: rgb(133, 133, 133);
  color: white;
  padding: 3px;
  font-size: .6em;
  border-radius: 4px;
  margin: 2px;
  width: 18px;
  text-align: center;
}
.dayBubbleUnset {
  color:  rgb(148, 148, 148);
  font-weight: 600;
  display: inline-block;
  padding: 3px;
  font-size: .6em;
  margin: 2px;
  width: 18px;
  text-align: center;
}
.daysOfWeekBar {
  display: inline-block;
}

.daysOfWeek {
  display: none;
  position: absolute;
  top: 7px;
  right: 60px;
}
.customSelect {
  color: #444;
}
.filterContainer {
  width: 100%;
  text-align: right;
  max-width: 600px;
  background-color: transparent;
  margin: 0 auto;
  margin-bottom: 15px;
  
  color: #444;
}
.filterHolder {
  display: inline-block;
  width: 280px;
  background-color: transparent;
  padding-right: 15px;
}
.addToRoutineButton {
  border: none;
  color: white;
  font-weight: 600;
  padding: 13px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  margin-bottom: 10px;
}
.button1 { 
  background-color: orange;
}
.button2 { 
  background-color: rgb(228, 228, 228);
  color: rgb(129, 129, 129);
}


.routineRequestText {
  width: 280px;
  display: inline-block;
  margin: 0 auto;
  margin-bottom: 15px;
  font-size: 1.1em;
}
.filterText {
  width: 250px;
  display: inline-block;
  margin: 0 auto;
  margin-bottom: 30px;

}

textarea {
  padding: 10px;
  resize: none;
  border: 1px solid rgb(197, 197, 197);
}


.tagChip {
  display: inline-block;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: rgb(158, 158, 158);
  color: black;
  border-radius: 4px;
  font-size: 1.15em;
  margin: 5px;
  font-weight: 500;
}
.routineTags {
  background-color: transparent;
  padding: 5px;
  margin-bottom: 20px;
  
    font-size: 1.00em;
    font-weight: 500;
  z-index: 100;
  display: absolute;
  top: 20px;
  left: 50%;
  cursor: pointer;
}
.routineLevel {
  color: rgb(105, 105, 105);
  font-size: 1.7em;
}




@media screen and (min-width: 375px) {
  .searchTitle {
    width: 210px;
    font-size: 1.4em;
  }
  
  .scheduleLine {
    padding-left: 20px;
  }

  .iconHolder {
    width: 60px;
    margin-right: 20px;
    margin-left: 10px;
  }

  .icon {
    width: 55px;
  }


}

@media screen and (min-width: 400px) {
  .searchTitle {
    width: 200px;
    font-size: 1.6em;
    font-weight: 550;

  }
  
}



@media screen and (min-width: 520px) {

  
  .starImg {
    width: 300px;
    height: 300px;
  }
  .searchTitle {
    width: 300px;
    font-size: 1.5em;
    font-weight: 570;
  }
  


}

@media screen and (min-width: 640px) {

  .detailsHolder > .rightSide {
    grid-column-start: 2;
    grid-row-start: 1;
  }
  
  .starImg {
    width: 350px;
    height: 350px;
  }

  .routineRequestText {
    width: 400px;
  }
  

}


@media screen and (min-width: 750px) {
  .daysOfWeek {
    display: inline-block;
  }
  
}


@media screen and (min-width: 900px) {
  #search_routineList  {
    padding-bottom: 120px;
  }
  .requestFormHolder {
    transform: translateY(-100px);
  }
  #search_routineList > li:last-child {

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .routineDetailWrapper {
    padding: 30px;
  }

  .searchTitle {
    width: 300px;
    font-size: 1.6em;
    font-weight: 600;
  }
  .iconHolder {
    margin-right: 35px;
  }
  .icon {
    width: 60px;
  }
  
  .starImg {
    width: 400px;
    height: 400px;
  }

  .itemWrapper {
    padding: 20px;
    }


}


</style>