<template>
  <v-app>
    <div class="container">
      <h1>About</h1>
      <br>
      <br>
      <v-card
        class="mx-auto"
        max-width="800"
      >
        <v-card-text>
          <div class="aboutContent">
           
            <p><span style="font-size: 1.6em;font-weight: 900; ">heedi</span><span style="font-size: 1.4em;"><i> is a new kind of clock that expands mindfulness for many purposeful routines.</i>&nbsp;&nbsp;</span></p>
            <br>
            <div style="text-align: center;">
              <img src="https://firebasestorage.googleapis.com/v0/b/heedi-1141.appspot.com/o/heediArticleCalloutImages%2Fa-new-kind-of-clock?alt=media&token=9e817f70-abd4-448f-87fd-9f4b77a68c64" width="100%" style="max-width:500px;" alt="heedi Purpose Clock" />
            </div>
            <br><br>
            <p>Like a clock, heedi tracks time, but does it differently.</p>
            <p>heedi takes multiple, dynamic purposes and breaks them down into a timeline of simple action steps.  At every moment, heedi counts down to the very next thing you want to do.</p>
            <p>It's called a "Purpose Clock".</p>
            
            
            <br>
            <p>More than a clock, heedi is a multi-function, meta-cognition tool for:</p>
            
            <ul>
              <li>
                <span style="font-size: 1.2em"><strong>Routine Planning:</strong></span>&nbsp;&nbsp;
                <router-link to="/routine">You search and follow</router-link> many, many routines from our growing library.&nbsp;&nbsp;heedi creates your Purpose Clock based on the routines you follow.
              </li>
              <li>
                <span style="font-size: 1.2em"><strong>Minding:</strong></span>&nbsp;&nbsp;
                heedi uses sounds, web notifications and visual dashboards to routinely get your attention and keep you mindful of all of your routines.
              </li>
              <li>
                <span style="font-size: 1.2em"><strong>Progress Pacing:</strong></span>&nbsp;&nbsp;
                 Always know your progress pace compared to the same point last week.&nbsp;&nbsp;&nbsp;Great for exercise, content creation and project building routines.
              </li>
              <li>
                <span style="font-size: 1.2em"><strong>Well-being Tracking:</strong></span>&nbsp;&nbsp;
                  Understand your journey more.&nbsp;&nbsp;Track your purpose-driven actions alongside how you feel.&nbsp;&nbsp;When tracked together, these data points form unique insights into your well-being.
              </li>
              
            </ul>


            <br><br>
            <div style="text-align: center;">
              <img src="https://firebasestorage.googleapis.com/v0/b/heedi-1141.appspot.com/o/assets%2Fheedar_at_lightswitch.png?alt=media&token=fa87f5c7-8bb2-4943-b626-3789d0a03c6c" width="100%" style="max-width:500px;" alt="heedi's Heedar display next to a lightswitch">
            </div>
            <br><br>
            <br><br>
            <p><span style="font-size: 1.3em;font-weight: 900; ">
              heedi is very easy to use.&nbsp;&nbsp;Please try it now by searching below:<br>
            </span></p>
            <br>
            
            
 
            <!--<p>You can follow 1 routine, or load yourself up with 20-30 routines.&nbsp;&nbsp;Other people may be following some of the same routines as you.&nbsp;&nbsp;The idea is that we can work together, share our ideas of what's working best, and together we collectively find the best way forward.&nbsp;&nbsp;In this social network, every routine forms a group where the members are anonymous and the common bond is more our actions than words.</p>-->
            <div class="searchContainer">
              <RoutineSearch />
            </div>
            <br>
            <routine-category-cloud />
            <br>
            
            
            <!--
            <p><span style="font-size: 1.3em;font-weight: 900; ">How It Works</span></p>
            

            <p>1.&nbsp;&nbsp;<router-link to="/routine"><strong>You search for and follow routines</strong></router-link> - as many as you think you need to accomplish what you want to do.  Your selected routines get combined together into one big routine.</p>

            <p>2.&nbsp;&nbsp;<strong>Each day, heedi creates a Day Plan</strong>.  The Day Plan changes each day and is solely based on the routines you follow. Each routine evolves differently over time, so trying to keep up with all the planning and upkeep without heedi would be nearly impossible.</p>

            <p>3.&nbsp;&nbsp;Throughout the day, <strong>heedi sends push notifications</strong> based on your Day Plan. You control the content and timing of these push notifications by adding/removing routines from your Purpose Clock.  Please Allow Notifications for heedi.</p>

            <p>4.&nbsp;&nbsp;heedi also provides you the <strong>Heedar heads-up-display</strong>. The Heedar dashboard display fits on any size screen. Multiple Heedars can be displayed around the home or office and remain in sync.  These dashboards were designed to work in the background of your life, yet provide <i>visual</i> alerts for the things you want to do.</p>
            
            <p>5.&nbsp;&nbsp;At the moment of taking action, heedi shines with <strong>dynamic Outcome Forms</strong> that make it easier to complete the items in your Day Plan.</p>
            <br><hr><br><br>
            -->
            

            <!--
            <p>heedi exists because modern life is placing higher demands on our most precious, scarcest resource: our cognition.</p>
            <p>Everything wants our attention, but are these distractions aligned with our purposes?  Likely not.  Our notification trays are filled with many actions to take, but they usually are not actions that directly support what we <strong>really</strong> want to do; things driven by personal purpose.</p>
            -->

            <br><hr><br>
            <p><span style="font-size: 1.4em;font-weight: 900; ">heedi provides a heads-up-display dashboard that you display within your physical environment</span></p>
            <br><br><hr><br>
            <p>When you really want to remember something, do you write it down on a note you can see and touch?</p>
            <p>It would be useful if these notes wrote themselves and were displayed continously over time telling us what we need to be doing in that moment to stay on track with all our goals.</p>
            <p>This is not about turning us all into mindless robots waiting to be told what to do.&nbsp;&nbsp;heedi is about giving positive reinforcement, building hope, and leaving us free to do even more.&nbsp;&nbsp;</p>
            <br>
            
            <img :src="require('@/assets/heedar.png')" width="100%" alt="heedi">
            
            <!--
            <p><strong>heedi was forged from large amounts of failure and disappointment</strong>, never being able to stick to a routine that could actualize our intentions.  Always wasting time planning routines, getting excited about the new plan, then only staying with it for a few weeks, if lucky, until everything falls apart and the ride is over.  Long-term commitment was a weakness, and this went on for decades.</p>  
            
            <p>heedi took 11 years to emerge after iterative attempts to use technology to alleviate this attention/focus problem.  We know we are not alone.</p>
            -->
           

            <br><hr><br><br>
            <p><span style="font-size: 1.4em;font-weight: 900; ">heedi was designed from the start knowing we will... not heed</span></p>
            <br><br><hr><br>

            <p><strong>There will always be times we lose steam. It's natural.</strong>&nbsp;&nbsp;heedi was built specifically to work through this by emphasisizing staying in the moment, not focusing too far into the future (imagine race horse with blinders on), and bringing together all the context and insights needed just in that moment to help us move forward to the next step.&nbsp;&nbsp;</p>
            <p>Simultaneously, heedi keeps rolling along, forgetting the past, and promoting small, incremental improvement from moment to moment.</p>

            <p>And when you do stop using heedi, but decide to come back, it will be as if you never left.&nbsp;&nbsp;heedi will get you right back into it.</p>
            <br><br>
            <hr><br>
            <p><span style="font-size: 1.4em;font-weight: 900; ">heedi is a "long-game" experience.  The magic comes from running your Purpose Clock in perpetuity.</span></p>
            <br><br><hr><br>


            <p>Please give heedi a try.&nbsp;&nbsp;Find a spare phone or tablet that connects to Wi-Fi,&nbsp;&nbsp;and place it somewhere in your immediate area at home or work.   Connect all these devices to your Main Console at heedi.com <i><b>with the screen always on</b></i>, like a clock.&nbsp;&nbsp;Search for and follow as many routines you can handle.&nbsp;&nbsp;Anonymously share your thoughts as you perform your actions.&nbsp;&nbsp;Tell us how you feel.&nbsp;&nbsp;Give us feedback on how we can improve.&nbsp;&nbsp;Together, we'll find the best way forward in everything we want to do.</p>
            <p>Pay attention to yourself, with us.</p>
            <p><b>heedi</b></p>
            <br>
            <br>
            <br>
            <span style="font-size: .85em;">
              <p><b>MISSION:  heedi's mission is to document the world's purposeful human action routines, make them easily accessible for everyone to follow, and share our collective experience to those coming after us.</b></p>
            </span>
            <br>
            <br>
            <br>
            <span style="font-size: .85em;">
              <p><b>heedi is commited to your data privacy:</b></p>
              <p>We strive to be fully compliant with all privacy laws, and to be exemplary stewards of your data.  Please review our full privacy policy at <router-link to="/privacy">https://www.heedi.com/privacy</router-link>.</p>
              <p>heedi was designed from the beginning to not rely on personal identifying information from its users.  Every heedi routine can be accessed by an anonymous account.  If you do sign-up for an official heedi account using Google Sign-in, we only receive your display name, email address and url to your Google profile pic.  All data collected by heedi is stored and protected by the most advanced cloud technologies in the world.&nbsp;&nbsp;</p>
            </span>
            
            <br><br>
          </div>
        </v-card-text>
        <news-wall :parent-view="'About'" />
      </v-card>
      
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <VueFooter :dark-text="true" />
    </div>
  </v-app>
</template>

<script>
  import '@/plugins/vuetify'
  import signinModule from '@/store/signin'
  import searchModule from '@/store/search'
  import userModule from '@/store/user'
  
  import NewsWall from '@/components/NewsWall'
  // import imageModule from '@/store/image'
  // import nestedModule from '@/store/nested'
  import settingsModule from '@/store/settings'
  //import MomentMaker from '@/components/MomentMaker'
  // import MevChains from '@/components/MevChains'
  import VueFooter from '@/components/VueFooter'
  import RoutineSearch from '@/components/RoutineSearch'
  // import SortableItem from '@/components/SortableItem'
  // import SortableList from '@/components/SortableList'
  //import ActionChains from '@/components/ActionChains'
  //import MomentHeedar from '@/components/MomentHeedar'
  //import feedbackModule from '@/store/feedback'
  // import experienceModule from '@/store/experience'
  import { mapActions} from 'vuex'
  import RoutineCategoryCloud from '../components/RoutineCategoryCloud.vue'
  //import draggable from 'vuedraggable'
  // import NestedDraggable2 from "@/components/nested/NestedDraggable2";
  // import RawDisplayer from "@/components/nested/RawDisplayer";

  let id = 1;

  export default {
    name: "about",
    components: {
      //MomentMaker,
      RoutineSearch,
      // MevChains,
      VueFooter,
      RoutineCategoryCloud,
      NewsWall,

    },
    data () {
      return {
        overlay: false,
        showFooterBool: false,
        heroImageSrc: "",
        items: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6', 'Item 7', 'Item 8'],


        



        list: [
          {
            name: "task 1",
            tasks: [
              {
                name: "task 2",
                tasks: []
              }
            ]
          },
          {
            name: "task 3",
            tasks: [
              {
                name: "task 4",
                tasks: []
              }
            ]
          },
          {
            name: "task 5",
            tasks: []
          }
        ],
      

        showBlock2: false,
        showBlock3: false,
        showBlock4: false,

        //vuedraggable variables
        enabled: true,
        dragging: false,
        /*
        list: [
          { name: "John", id: 0 },
          { name: "Joao", id: 1 },
          { name: "Jean", id: 2 }
        ],
        */
      }
    },
    computed: {
      draggingInfo() {
        return this.dragging ? "under drag" : "";
      },
      elements: {
          get() {
            return this.$store.state.nested.elements;
          },
          set(value) {
            this.$store.commit("nested/updateElements", value, {root: true});
          }
        },
      
    },
    methods: {
      ...mapActions('signin', [
        'autoSignIn'
      ]),
      ...mapActions('search', [
        'setShowSearchIcon'
      ]),
      showFooter () {
        this.showFooterBool = true;
      },
      add: function() {
      this.list.push({ name: "Juan " + id, id: id++ });
      },
      replace: function() {
        this.list = [{ name: "Edgard", id: id++ }];
      },
      checkMove: function(e) {
        window.console.log("Future index: " + e.draggedContext.futureIndex);
      }
    },
    watch: {

    },
    async beforeCreate () {
      //MUST HAVE ALL THREE OF THESE MODULES LOADED FOR AUTOSIGNIN TO WORK
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      if(!this.$store.state.search) this.$store.registerModule('search', searchModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
      //if(!this.$store.state.image) this.$store.registerModule('image', imageModule)
      //if(!this.$store.state.experience) this.$store.registerModule('experience', experienceModule)
      //if(!this.$store.state.nested) this.$store.registerModule('nested', nestedModule)
  
    },
    created () {
      /* eslint-disable no-undef */
      gtag('config', 'G-H9RD8W4MLR', {'page_path': '/about'});
      //this.autoSignIn()
      this.setShowSearchIcon(true)
    },
    mounted () {
      
    },
    updated () {

    }
  }
</script>

<style scoped>

  li {
    margin-bottom: 15px;
  }


  .about {
    min-height: 400px;
  }

  .blockWrapper {
    background-color: white;
    padding: 30px;
    
    margin: 0 auto;
  }

  .statement {
    display: block;
    text-align: center;
    font-size: 1.5em;
    font-weight: 300;
    max-width: 500px;
    margin: 0 auto;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 30px;


  }



  .mean {
    font-size: 1.9em;
    font-weight: 500;
  }


  .end {
    font-size: 1.4em;
    font-weight: 300;
  }

  


  .moreLink {
    display: block;
    width: 30%;
    max-width: 200px;
    border: 2px solid #999;
    padding: 3px;
    margin: 0 auto;
    text-align: center;
    margin-top: 50px;
    color: #555;
    border-radius: 10px;
    background-color: #ddd;
    
  }
  .moreLink:hover {
    background-color: #000;
    color: white;
  }

  .downIcon {
    color: #777;
  }


  .buttons {
    margin-top: 35px;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  .aboutContent {
    font-family: 'Quicksand', sans-serif;
    font-size: 1.5em;
    font-weight: 400;
    margin: 0 auto;
  }

  .searchContainer {
    text-align: center;
    font-size: .6em;
  }

  @media screen and (min-width: 600px) {
    .aboutContent {
      width: 80%;
      margin-top: 30px;
    }

  }


</style>